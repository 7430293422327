import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import styles from './iframe-promo.module.scss';
import Link from '../link/link';

const IframePromo = ({ content }) => {
  const image = {
    resolutions: content.relationships && content.relationships.field_image && content.relationships.field_image.localFile && content.relationships.field_image.localFile.childImageSharp
      ? content.relationships.field_image.localFile.childImageSharp.resolutions
      : null,
    alt: content.field_image && content.field_image.alt
      ? content.field_image.alt
      : null
  };

  return (
    <div className={styles.promo}>
      {/* If there's a webpage url provided, link to it. */}
      {content.field_webpage && content.field_webpage.uri && (
        <Link to={content.field_webpage.uri}>
          {image.resolutions && (
            <Img
              className={styles.image}
              resolutions={image.resolutions}
              alt={image.alt}
            />
          )}
        </Link>
      )}
      {/* If not, only display the image. */}
      {content.field_webpage && !content.field_webpage.uri &&
        (image.resolutions && (
          <Img
            className={styles.image}
            resolutions={image.resolutions}
            alt={image.alt}
          />
        ))}
      <h2 className={styles.title}>{content.title}</h2>
      <div
        className={styles.summary}
        dangerouslySetInnerHTML={{
          __html: content.field_summary ? content.field_summary.processed : ''
        }}
      />
      {/* Show a call to action link if a webpage url is provided. */}
      {content.field_webpage && content.field_webpage.uri && (
        <Link
          className={styles.readMore}
          aria-label={`Learn More about ${content.title}`}
          to={content.field_webpage.uri}
        >
          Learn More
        </Link>
      )}
    </div>
  );
};

IframePromo.propTypes = {
  content: PropTypes.object
};

export default IframePromo;
