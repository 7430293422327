import React from 'react';
import PropTypes from 'prop-types';

// Global Styles.
import '../../global/fonts/fonts.scss';
import '../../global/global.scss';

const IframeLayout = ({ children }) => <div id="iframe-layout">{children}</div>;

IframeLayout.propTypes = {
  children: PropTypes.node
};

export default IframeLayout;
